@import '../../style/variables.sass'

.comics

    &__list
        margin-top: 50px

    &__grid
        display: grid
        grid-template-columns: repeat(auto-fill, 225px)
        justify-content: space-between
        column-gap: 10px
        row-gap: 55px

    &__item
        width: 225px
        height: 415px
        cursor: pointer
        transition: 0.3s transform

        img
            width: 225px
            height: 345px

        &:hover            
            box-shadow: 0 5px 20px $main-color
            transform: translateY(-8px)

        &-enter
            opacity: 0
            transform: scale(0.2)

        &-enter-active
            opacity: 1
            transform: translateX(0)
            transition: all 500ms ease-in

        &-enter-done
            opacity: 1
            transform: translateX(0)

        &-exit
            opacity: 1

        &-exit-active
            opacity: 0
            transform: scale(0.2)
            transition: all 500ms ease-in

        &-exit-done
            opacity: 0

    &__name
        margin-top: 10px
        font-weight: 700
        font-size: 14px
        line-height: 16px
        text-transform: uppercase
        color: $dark
        padding-right: 10px

    &__price
        margin-top: 5px
        font-weight: 700
        font-size: 14px
        line-height: 16px
        color: rgba(0, 0, 0, 0.6)