@import '../../../style/variables.sass'

.single-comic 
    margin-top: 50px
    display: grid
    grid-template-columns: 293px auto auto
    column-gap: 50px
    align-items: start
    &__img 
        width: 293px
        height: 450px
        object-fit: cover
    
    &__name 
        font-weight: bold
        font-size: 22px
        line-height: 29px
    
    &__descr 
        font-size: 18px
        line-height: 24px
        margin-top: 25px
    
    &__price 
        font-weight: bold
        font-size: 24px
        line-height: 32px
        color: $main-color
        margin-top: 25px
    
    &__back 
        justify-self: end
        font-weight: bold
        font-size: 18px
        line-height: 24px
        margin-bottom: 20px
        &:hover 
            color: $main-color