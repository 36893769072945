@import '../../style/variables.sass'

.app 
    &__header
        display: flex
        justify-content: space-between

    &__title
        font-weight: bold
        font-size: 28px
        line-height: 37px
        span
            color: $main-color

    &__menu
        ul
            display: flex
            justify-content: space-between
            font-weight: bold
            font-size: 24px
            line-height: 32px
            li
                margin: 0 8px
                a:hover
                    color: $main-color