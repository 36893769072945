@import '../../style/variables.sass'

.char
    &__sidebar
        position: sticky
        top: 0
        display: grid
        grid-template-rows: auto minmax(127px, auto)
        row-gap: 30px

    &__info
        padding: 25px
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25)        
        z-index: 5
        background-color: #fff

        &-name
            font-weight: bold
            font-size: 22px
            line-height: 29px
            text-transform: uppercase

        .skeleton
            margin-top: 30px

    &__basics
        display: grid
        grid-template-columns: 150px auto
        column-gap: 25px

        img
            width: 150px
            height: 150px
            // object-fit: cover

    &__btns
        margin-top: 35px
        a:nth-child(2)
            margin-top: 10px

    &__descr 
        font-size: 14px
        line-height: 18px
        margin-top: 15px

    &__comics
        font-weight: bold
        font-size: 18px
        line-height: 24px
        margin-top: 10px

        &-list
            position: relative
            margin-top: 10px        

        &-item
            width: 100%
            margin-top: 10px   
            padding: 0 10px           
            line-height: 25px
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)