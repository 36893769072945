@import '../../style/variables.sass'
    
.app__baner
    width: 100%
    height: 100px
    background-color: $dark
    padding: 0 25px 0 45px
    display: grid
    grid-template-columns: 152px auto 133px

    &-text        
        font-weight: bold
        font-size: 24px
        line-height: 32px
        color: #FFFFFF
        padding-left: 83px
        padding-top: 18px